/* eslint-disable no-else-return */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable dot-notation */
import axios from 'axios';
import { toast } from 'react-toastify';
// import { useSnackbar } from 'notistack';
// config
import { HOST_API } from '../config';
import { logoutUser } from '.';
import { ShowNotistack, useNotification } from './showNotistack';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
  (error) => {
    // const { enqueueSnackbar } = useSnackbar();
    // const { showError } = useNotification(); // Use the notification service

    if (error.response) {

      console.log(error)

      if (error.response.status === 401) {
        logoutUser();
        // navigate(routes.auth.login);
        window.location.href = "/auth/login"
      }

        const myObject = error.response.data["errors"];
        if (myObject) {
          Object.keys(myObject).map(function (key)  {
            const text = myObject[key][0];
            // enqueueSnackbar(text, { variant: 'error' });
            // showError(text);
            // ShowNotistack(text);
            toast.error(text)
          });
        } else {
          // showError(error.response.data["message"]);
          toast.error(error.response.data["message"])
        }
        
      } else {
        // showError("Server Error");
        toast.error("Server Error")
        // enqueueSnackbar("Server Error", { variant: 'error' });
      }
      const expectedErrors =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;
      if (!expectedErrors) {
        // setTimeout(()=>{toast.dismiss("loadingToast")},700)

        // localStorage.removeItem("token");
      }
      // return Promise.reject(error);
      if (error.response) {
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  // }
);

export default axiosInstance;
