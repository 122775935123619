import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40,display : 'flex' , alignItems : "center" , justifyContent:"center" , ...sx }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
      <svg width="35" height="27" viewBox="0 0 35 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 1678">
          <path id="Vector" d="M17.1038 21.6916C16.0356 19.5787 15.0288 17.589 14.0221 15.5994C12.3309 12.2528 10.6453 8.902 8.94459 5.55955C8.57986 4.84232 8.19327 4.13478 7.76161 3.45632C6.74393 1.85294 5.56642 0.439255 3.53516 0.0571028C3.69361 0.0432567 3.85344 0.0183337 4.0119 0.0169491C5.99262 0.0141798 7.97335 0.0335644 9.95407 0.00171834C10.4691 -0.00658933 10.7149 0.19141 10.9376 0.637255C12.85 4.46432 14.7857 8.28031 16.7159 12.1004C16.8183 12.304 16.929 12.5048 17.0983 12.8232C17.2705 12.5297 17.4112 12.3164 17.5259 12.0894C19.4465 8.29138 21.374 4.49755 23.2714 0.688485C23.5186 0.19141 23.7959 -0.00935855 24.3601 0.000333725C26.4761 0.0363336 28.592 0.0141798 30.8405 0.0141798C30.6793 0.123564 30.626 0.188641 30.5659 0.196948C29.1343 0.388025 28.0729 1.21602 27.2697 2.34448C26.5143 3.40648 25.8245 4.53078 25.2289 5.69247C22.6389 10.7491 20.094 15.8306 17.5314 20.9024C17.4194 21.124 17.295 21.3386 17.1052 21.6903L17.1038 21.6916Z" fill="#103B58" />
          <path id="Vector_2" d="M0 5.7256C1.78538 5.7256 3.57623 5.64391 5.35615 5.75329C6.5801 5.82944 7.53222 6.57437 8.10048 7.68898C9.98012 11.3776 11.8461 15.0731 13.682 18.7839C14.2298 19.8902 14.1574 21.0712 13.5085 22.0972C12.5551 23.6065 11.4855 25.0395 10.4514 26.5252C10.3954 26.438 10.3503 26.3923 10.3585 26.3771C11.1658 24.944 11.0811 23.45 10.5962 21.9574C10.3189 21.1059 9.98832 20.264 9.59081 19.4637C8.2002 16.664 6.78501 13.8768 5.34659 11.1007C4.86165 10.1647 4.32754 9.24528 3.73332 8.37574C2.82629 7.04929 1.6802 6.02606 0 5.72145L0 5.7256Z" fill="#8C2230" />
          <path id="Vector_3" d="M34.3851 5.68457C31.9495 6.15949 30.6913 7.9761 29.6463 9.94087C27.8774 13.2653 26.2013 16.6396 24.5101 20.0056C24.2028 20.6176 23.9774 21.2794 23.7711 21.9371C23.2944 23.4533 23.1687 24.9639 24.0443 26.4067C23.9746 26.4247 23.9145 26.4524 23.9063 26.4413C22.8818 25.004 21.8027 23.6014 20.8615 22.1102C20.173 21.0191 20.1621 19.7938 20.7563 18.6099C22.5513 15.0362 24.3462 11.4626 26.1493 7.89302C26.8925 6.42257 28.0905 5.68457 29.7242 5.68457C31.2774 5.68457 32.8305 5.68457 34.3851 5.68457Z" fill="#8C2230" />
        </g>
      </svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
