import axiosInstance from "../utils/axios";

// Profile

export const adminProfile = () => axiosInstance.get("/api/admin/profile",);

// Admin

export const createAdmin = (data) => axiosInstance.post("/api/admin",data);

export const editAdmin = (id , data) => axiosInstance.put(`/api/admin/${id}`,data);

export const getAllAdmin = (page = 1 , perPage = 10) => axiosInstance.get(`/api/admin?page=${page}&perPage=${perPage}` );

export const getAdminDetail = (id) => axiosInstance.get(`/api/admin/${id}` );

// Role

export const getAllRole = (page = 1 , perPage = 10) => axiosInstance.get(`/api/admin/role?page=${page}&perPage=${perPage}` );

export const getRoleDetail = (id) => axiosInstance.get(`/api/admin/role/${id}` );

export const createRole = (data) => axiosInstance.post(`/api/admin/role` , data );

export const editRole = (id , data) => axiosInstance.put(`/api/admin/role/${id}` , data );

// Permission

export const getAllPermission = (page = 1 , perPage = 10) => axiosInstance.get(`/api/admin/permission?page=${page}&perPage=${perPage}` );