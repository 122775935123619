import { store } from "../redux/store";

export const hasAccess = (accessPermission) => {
    const { admin } = store.getState();
    const userPermissions = admin?.data?.role?.permission || [];

    if (admin?.data?.isSuperadmin) return true;

    if (typeof accessPermission === 'string') {
        return userPermissions?.findIndex?.(permission => `${permission?.model} ${permission?.action}` === accessPermission) > -1;
    } 
    if (accessPermission?.length) {
        return !!userPermissions?.find?.(permission =>  accessPermission?.include?.(`${permission?.model} ${permission?.action}`));
    }
    return true;

};