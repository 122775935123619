import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';
import { adminProfile } from '../../services/admin';

// ----------------------------------------------------------------------

const initialState = {
  isLoading : false ,
  data:{},
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // END LOADING
    endLoading(state) {
      state.isLoading = false;
    },

    // INIT
    getUserData(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function getAdminProfile() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const {data} = await adminProfile();
      dispatch(slice.actions.getUserData(data));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
      console.log(error)
    }
  };
}
