/* eslint-disable array-callback-return */
/* eslint-disable dot-notation */

import { toast } from "react-toastify";

export const NUM_REGEX = /\D/g;

export const YES_NO_OPTIONS = [
    {
        label: "خیر",
        value: 0,
    },
    {
        label: "بله",
        value: 1,
    },
]

export const separateNumberWithComma = (myNumber) => {

    if (!myNumber && myNumber !== 0) return

    return myNumber
        .toString()
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getFileTypeByUrl = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    
    if (['jpeg', 'jpg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
        return 'image';
    } 
    if (['doc', 'docx'].includes(extension)) {
        return 'word document';
    } 
    if (['zip', 'rar', '7z'].includes(extension)) {
        return 'zip archive';
    } 
    return 'unknown';
    
}

export const handleGenerateLink = (link) => {

    if (link) {
        if (link?.search("https://") === -1 || link?.search("http://") === -1) {
            return link?.split("www.")[1] || link
        }
        return new URL(link || "").hostname?.split("www.")[1] || new URL(link).hostname

    }

    return ""

}

export const isAuthenticated = () => {
    const token = window.localStorage.getItem('accessToken');
    if (token) {
        return true
    }
    return false
}

export const logoutUser = () => {
    localStorage.removeItem("accessToken");
}

export const generateErrorArray = (error) => {
    const errorArr = [];

    if (error.response) {
        const myObject = error.response.data["errors"];
        if (myObject) {
            Object.keys(myObject).map((key) => {
                const text = myObject[key][0];
                errorArr.push(text)

            });
        } else {
            errorArr.push(error.response.data["message"])
        }

    } else {
        errorArr.push("مشکلی پیش آمده است")
    }

    return errorArr

}

export const downloadFileFromAxios = (responseData , title = "") => {
    // create file link in browser's memory
    // const href = URL.createObjectURL(responseData);
    const href = window.URL.createObjectURL(new Blob([responseData]));


    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${title}.xlsx`); // or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}

export const copyText = (text) => {
	// Create a temporary textarea element
	const textarea = document.createElement("textarea");
	textarea.value = text;
	// Make the textarea out of view but still in the DOM to allow for selection
	textarea.style.position = "fixed";
	textarea.style.opacity = 0;
	document.body.appendChild(textarea);

	// Select the text inside the textarea
	textarea.select();
	textarea.setSelectionRange(0, 99999);

	// Copy the selected text
	navigator.clipboard.writeText(textarea.value);

	// Clean up: remove the textarea from the DOM
	document.body.removeChild(textarea);

	// Inform the user that the text has been copied
	toast.info("Copied!" , { autoClose: 5000 });
	// console.log({text})
	// const textarea = document.createElement("textarea");
	// textarea.value = text;
	// document.body.appendChild(textarea);

	// textarea.select();
	// textarea.setSelectionRange(0, 99999);

	// document.execCommand("copy");

	// document.body.removeChild(textarea);

	// toast.info("Copied!");
};