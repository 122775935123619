// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';
import { adminPermission, newsAgencyPermission, rolePermission, sellerPermission, ticketPermission, transactionPermission } from '../../../permission';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'داشبورد',
  //   items: [
  //     { title: 'اعلانات', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  // { title: 'اخبار', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  // { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
  // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'مدیریت سایت',
    items: [
      { title: 'اعلانات', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      // USER
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.root,
      //   icon: ICONS.user,
      //   children: [
      //     { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //     { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //     { title: 'list', path: PATH_DASHBOARD.user.list },
      //     { title: 'create', path: PATH_DASHBOARD.user.new },
      //     { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //     { title: 'account', path: PATH_DASHBOARD.user.account },
      //   ],
      // },

      // E-COMMERCE
      // {
      //   title: 'e-commerce',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.cart,
      //   children: [
      //     { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
      //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
      //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new },
      //     { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit },
      //     { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
      //   ],
      // },

      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      // BLOG
      // {
      //   title: ' اخبار',
      //   path: PATH_DASHBOARD.blog.root,
      //   icon: ICONS.blog,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.blog.posts },
      //     // { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'ایجاد', path: PATH_DASHBOARD.blog.new },
      //     {
      //       title: 'مدیریت نویسنده ها',
      //       children:[
      //         { title: 'لیست', path: PATH_DASHBOARD.blog.authorsList },

      //       ]
      //     }
      //   ],
      // },

      // REPORTAGE
      {
        title: 'انتشار رپورتاژ',
        path: PATH_DASHBOARD.reportage.publish.list,
        icon: ICONS.blog,
        // children: [
          // { title: 'لیست', path: PATH_DASHBOARD.reportage.list },
          // { title: 'ایجاد', path: PATH_DASHBOARD.reportage.new },
          // { title: 'رپورتاژ های ثبت شده', path: PATH_DASHBOARD.reportage.publish.list },
        // ],
      },

      // BACKLINK
      {
        title: 'انتشار بک لینک',
        path: PATH_DASHBOARD.backlink.publish.list,
        icon: ICONS.blog,
        // children: [
          // { title: 'لیست', path: PATH_DASHBOARD.reportage.list },
          // { title: 'ایجاد', path: PATH_DASHBOARD.reportage.new },
          // { title: 'رپورتاژ های ثبت شده', path: PATH_DASHBOARD.reportage.publish.list },
        // ],
      },

      // TICKETS
      {
        title: 'تیکت ها',
        path: PATH_DASHBOARD.ticket.list,
        icon: ICONS.chat,
        permission: ticketPermission.read
      },

      // FINANCIAL
      {
        title: 'پرداختی ها',
        path: PATH_DASHBOARD.financial.root,
        icon: ICONS.banking,
        permission: transactionPermission.read,
        children: [
          { title: 'فیش های واریزی', path: PATH_DASHBOARD.financial.receipt.list },
          { title: 'درگاه پرداخت', path: PATH_DASHBOARD.financial.list },
        ],
      },

      // NEWS AGENCY
      {
        title: 'مدیریت پلن رسانه ها',
        path: PATH_DASHBOARD.newsAgency.root,
        icon: ICONS.invoice,
        permission: newsAgencyPermission.read
        // children: [
        //   { title: 'لیست', path: PATH_DASHBOARD.newsAgency.list },
        //   { title: 'ایجاد', path: PATH_DASHBOARD.newsAgency.new },
        // ],
      },
      
      // MANAGING SITE
      {
        title: 'مدیریت داده های سایت',
        path: PATH_DASHBOARD.managingSite.root,
        icon: ICONS.analytics,
        permission: transactionPermission.read,
        children: [
          { title: 'تخفیفات جامع VIP', path: PATH_DASHBOARD.managingSite.discount },
        ],
      },

      
      {
        title: 'لیست کاربران',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
        permission: adminPermission.read
        // children: [
        // { title: 'لیست', path: PATH_DASHBOARD.admin.list },
        // { title: 'ایجاد', path: PATH_DASHBOARD.admin.new },
        // ],
      },


      // SELLER
      {
        title: 'لیست فروشندگان',
        path: PATH_DASHBOARD.seller.list,
        icon: ICONS.cart,
        permission: sellerPermission.read
        // children: [
        //   { title: 'لیست', path: PATH_DASHBOARD.seller.list },
        //   { title: 'ایجاد', path: PATH_DASHBOARD.seller.new },
        // ],
      },


      // REPORTAGE PACKAGES
      {
        title: 'پکیج های رپورتاژ',
        path: PATH_DASHBOARD.reportagePackage.list,
        icon: ICONS.cart,
        permission: newsAgencyPermission.read
        // children: [
        //   { title: 'لیست', path: PATH_DASHBOARD.seller.list },
        //   { title: 'ایجاد', path: PATH_DASHBOARD.seller.new },
        // ],
      },
      // REPORTAGE PACKAGES
      {
        title: 'کد تخفیف',
        path: PATH_DASHBOARD.discountCode.list,
        icon: ICONS.cart,
        permission: newsAgencyPermission.read
        // children: [
        //   { title: 'لیست', path: PATH_DASHBOARD.seller.list },
        //   { title: 'ایجاد', path: PATH_DASHBOARD.seller.new },
        // ],
      },

      
      {
        title: 'ادمین ها',
        path: PATH_DASHBOARD.admin.list,
        icon: ICONS.user,
        permission: adminPermission.read
        // children: [
        // { title: 'لیست', path: PATH_DASHBOARD.admin.list },
        // { title: 'ایجاد', path: PATH_DASHBOARD.admin.new },
        // ],
      },


      // REPORTS
      {
        title: 'گزارش ها',
        path: PATH_DASHBOARD.report.root,
        icon: ICONS.analytics,
        permission: adminPermission.read,
        children: [
          { title: 'گزارش مالی رپورتاژها', path: PATH_DASHBOARD.report.reportage },
          { title: 'گزارش مالی بک لینک ها', path: PATH_DASHBOARD.report.backlink },
        ],
      },


      {
        title: 'نقش ها',
        path: PATH_DASHBOARD.role.list,
        icon: ICONS.user,
        permission: rolePermission.read
        // children: [
        // { title: 'لیست', path: PATH_DASHBOARD.admin.list },
        // { title: 'ایجاد', path: PATH_DASHBOARD.admin.new },
        // ],
      },
      {
        title: 'نوتیفیکیشن',
        path: PATH_DASHBOARD.notification.list,
        icon: ICONS.invoice,
        // permission: rolePermission.read
        // children: [
        // { title: 'لیست', path: PATH_DASHBOARD.admin.list },
        // { title: 'ایجاد', path: PATH_DASHBOARD.admin.new },
        // ],
      },
      {
        title: 'لاگ سیستم',
        path: PATH_DASHBOARD.systemLog.list,
        icon: ICONS.user,
        // permission: rolePermission.read
        // children: [
        // { title: 'لیست', path: PATH_DASHBOARD.admin.list },
        // { title: 'ایجاد', path: PATH_DASHBOARD.admin.new },
        // ],
      },
      {
        title: 'مدیریت اطلاعات',
        path: "#",
        icon: ICONS.kanban,
        // permission:rolePermission.read
        children: [
          { title: 'نقش های تخفیفی', path: PATH_DASHBOARD.clientType.list },
          { title: 'کشور', path: PATH_DASHBOARD.country.list },
          { title: 'زبان', path: PATH_DASHBOARD.language.list },
        ],
      },

      // STORE
      // {
      //   title: ' محتوای ویژه',
      //   path: PATH_DASHBOARD.specialContent.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'لیست', path: PATH_DASHBOARD.specialContent.list },
      //     // { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     { title: 'ایجاد', path: PATH_DASHBOARD.specialContent.new },
      //     { title: 'ایجاد لیست محتوای ویژه', path: PATH_DASHBOARD.specialContent.new_playlist },
      //   ],
      // },
    ],
  },

  // Panel
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'مدیریت پنل',
  //   items: [
  //     // Admin
  //     {
  //       title: 'مدیریت ادمین ها',
  //       path: PATH_DASHBOARD.admin.list,
  //       icon: ICONS.user,
  //       children: [
  //         // { title: 'لیست', path: PATH_DASHBOARD.admin.list },
  //         // { title: 'ایجاد', path: PATH_DASHBOARD.admin.new },
  //       ],
  //     },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: PATH_DASHBOARD.mail.root,
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
  //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
  //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
  //   ],
  // },
];

export default navConfig;
